import React from 'react';

export default class Welcome extends React.Component {
  render() {
    return (
      <div>
        <div>
          <br></br>
          <h3 className="d-flex justify-content-center"> Welcome to my corner of the internet. </h3>
          <h4 className="d-flex justify-content-center"> Enjoy learning more about me and the projects I work on. </h4>
          <br></br>
        </div>
      </div>
    );
  }
}
